:root {
    --main-background-color: #f8fafc;
}

* {
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    font-family: "Lato", serif;
    background-color: var(--main-background-color);
}

